import { ref, watch, computed,onMounted  } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function businessHandler() {
  // Use toast
  const toast = useToast()

  const refBusinessListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ruc', label: 'RUC / DNI', sortable: false },
    { key: 'business_name', label: 'Razón social', sortable: false },
    { key: 'status_business', label: 'Estado', sortable: false },
    { key: 'payment_qualification', label: 'C. de pago', sortable: false },
    { key: 'seller', label: 'Vendedor', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalBusiness = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const serviceInterest = ref([])

  const refetchData = () => {
    refBusinessListTable.value.refresh()
  }

  watch([currentPage, searchQuery], () => {
    refetchData()
  })

  const fetchBusiness = (ctx, callback) => {
    store
      .dispatch('app-business/fetchBusiness', {
        searchText: searchQuery.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalBusiness.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchServiceInterest = () => {
    store
      .dispatch('app-business/fetchServiceInterest')
      .then(response => {
        serviceInterest.value = response.data.payload.results
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar lista de servicios de interés',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    fetchServiceInterest()
  })

  const resolveStatusVariant = status => {
    if (status === 'CLIENTE') return 'success'
    if (status === 'POSIBLE CLIENTE') return 'info'
    return 'primary'
  }

  const resolveStatusPaymentQualification = paymentQualification => {
    if (paymentQualification && paymentQualification.slug === 'bad') return 'danger'
    if (paymentQualification && paymentQualification.slug === 'good') return 'warning'
    if (paymentQualification && paymentQualification.slug === 'excellent') return 'success'
    return 'primary'
  }

  return {
    resolveStatusVariant,
    resolveStatusPaymentQualification,

    fetchBusiness,
    serviceInterest,
    tableColumns,
    perPage,
    currentPage,
    totalBusiness,
    searchQuery,
    refBusinessListTable,
    refetchData,
  }
}
