import { render, staticRenderFns } from "./businessSidebar.vue?vue&type=template&id=8b0fff24&scoped=true&"
import script from "./businessSidebar.vue?vue&type=script&lang=js&"
export * from "./businessSidebar.vue?vue&type=script&lang=js&"
import style0 from "./businessSidebar.vue?vue&type=style&index=0&id=8b0fff24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b0fff24",
  null
  
)

export default component.exports