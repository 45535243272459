<template>
  <div class="">
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserCheckIcon"
          :statistic="statistics.client"
          color="success"
          statistic-title="Clientes"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="statistics.possibleClient"
          color="info"
          statistic-title="Posible cliente"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="statistics.clientRUC20"
          statistic-title="Registro RUC 20"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="warning"
          :statistic="statistics.clientRUC10"
          statistic-title="Registros RUC 10-DNI"
        />
      </b-col>
    </b-row>
    <b-card
      no-body
    >
      <business-sidebar
        v-model="isBusinessHandlerSidebarActive"
        :business="businessObject"
        :clear-data="resetBusinessData"
        :service-interest="serviceInterest"
        :payment-qualifications="paymentQualifications"
        @add-business="addBusiness"
        @update-business="updateBusiness"
      />
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de empresas</h3> <small> (Hay {{ totalBusiness }} cuentas)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por nombre o ruc"
              />
              <b-button
                v-can-permissions="'Creacion de empresas'"
                variant="primary"
                @click="createBusiness"
              >
                Crear
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refBusinessListTable"
        :items="fetchBusiness"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        class="position-relative"
      >
        <!-- Column: Id -->

        <!-- Column: Issued Date -->
        <template #cell(business_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.business_name)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.business_name }}
            </span>
            <!--          <small class="text-muted">{{ data.item.business_name }}</small>-->
          </b-media>
        </template>

        <template #cell(status_business)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status_business)}`"
            class="text-capitalize"
          >
            {{ data.item.status_business }}
          </b-badge>
        </template>

        <template #cell(payment_qualification)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusPaymentQualification(data.item.payment_qualification)}`"
            class="text-capitalize"
          >
            {{ data.item.payment_qualification ? data.item.payment_qualification.name : 'Sin calificar' }}
          </b-badge>
        </template>

        <template #cell(seller)="data">
          <span class="text-nowrap">
            {{ data.item.seller.id !== 3 ? data.item.seller.name : 'Sin asignar' }}
          </span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <span v-can-permissions="'Editar empresas'">
              <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editBusiness(data.item)"
              />
              <b-tooltip
                title="Editar"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />
            </span>

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="deleteBusiness(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!--        <b-col-->
          <!--          cols="12"-->
          <!--          sm="6"-->
          <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
          <!--        >-->
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          <!--        </b-col>-->
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBusiness"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import businessStoreModule from '@/views/dramox/accounts/business/businessStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import businessHandler from '@/views/dramox/accounts/business/businessHandler'
import { avatarText } from '@core/utils/filter'
import BusinessSidebar from '@/views/dramox/accounts/business/sidebar/businessSidebar.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import router from '@/router'

export default {
  name: 'HomeBusiness',
  components: {
    StatisticCardHorizontal,
    BusinessSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BBadge,
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-business'
    const isBusinessHandlerSidebarActive = ref(false)
    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, businessStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const paymentQualifications = ref([])

    const blankBusinessData = {
      ruc: '',
      business_name: '',
      website: '',
      phone: '',
      address_fiscal: '',
      address_fiscal_1: '',
      address_fiscal_2: '',
      department: '',
      province: '',
      district: '',
      service_interest: '',
      payment_qualification_id: null,
    }

    const statistics = ref({
      client: 0,
      possibleClient: 0,
      clientRUC20: 0,
      clientRUC10: 0,
    })

    const businessObject = ref(JSON.parse(JSON.stringify(blankBusinessData)))
    const resetBusinessData = () => {
      businessObject.value = JSON.parse(JSON.stringify(blankBusinessData))
    }

    const {
      serviceInterest,
      tableColumns,
      currentPage,
      totalBusiness,
      perPage,
      searchQuery,
      refBusinessListTable,
      refetchData,
      fetchBusiness,
      resolveStatusVariant,
      resolveStatusPaymentQualification,
    } = businessHandler()

    const editBusiness = item => {
      isBusinessHandlerSidebarActive.value = true
      businessObject.value = item
    }

    const createBusiness = () => {
      isBusinessHandlerSidebarActive.value = true
    }

    const addBusiness = val => {
      store.dispatch('app-business/addBusiness', { body: val })
        .then(() => {
          showNotification({ title: 'Empresa creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          router.push({ name: 'contacts' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }
    const updateBusiness = val => {
      store.dispatch('app-business/updateBusiness', { id: val.id, body: val })
        .then(() => {
          showNotification({ title: 'Empresa actualizada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }
    const deleteBusiness = val => {
      store.dispatch('app-business/deleteBusiness', { id: val.id })
        .then(() => {
          showNotification({ title: 'Empresa eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]

          showNotification({ title: messageError, icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-business/fetchStatistics')
      .then(response => {
        const { results } = response.data.payload
        statistics.value = results
      })
      .catch(() => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    store.dispatch('app-business/fetchPaymentQualifications')
      .then(response => {
        const { results } = response.data.payload
        paymentQualifications.value = results
      })
      .catch(() => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    return {
      businessObject,
      resetBusinessData,

      fetchBusiness,
      serviceInterest,
      tableColumns,
      currentPage,
      totalBusiness,
      searchQuery,
      perPage,
      refBusinessListTable,
      refetchData,

      isBusinessHandlerSidebarActive,

      avatarText,

      createBusiness,
      addBusiness,
      updateBusiness,
      editBusiness,
      deleteBusiness,

      resolveStatusVariant,
      resolveStatusPaymentQualification,
      statistics,

      paymentQualifications,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
