<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isBusinessHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-business-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ objetLocal.id ? 'Editar empresa' : 'Agregar empresa' }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- RUC / DNI -->
            <validation-provider
              #default="validationContext"
              name="RUC / DNI"
              :rules="{ required: true, uniqueRuc: objetLocal.id,digits_8_or_11: true }"
            >
              <b-form-group
                label="RUC / DNI"
                label-for="ruc_dni"
              >
                <b-form-input
                  id="ruc_dni"
                  v-model="objetLocal.ruc"
                  max-length="11"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  @input="existsBusinessByRuc"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <div
                  v-if="existsInfo.businessName && existsInfo.businessId !== objetLocal.id"
                  class="mt-1"
                >
                  <b>EMPRESA: {{ existsInfo.businessName }}</b> <br> <b>VENDEDOR: {{ existsInfo.sellerName }}</b>
                </div>
              </b-form-group>
            </validation-provider>

            <template v-if="existsRuc || objetLocal.id">
              <!-- Business name -->
              <validation-provider
                #default="validationContext"
                name="Razón social"
                rules="required"
              >
                <b-form-group
                  label="Razón social"
                  label-for="business_name"
                >
                  <b-form-input
                    id="business_name"
                    v-model="objetLocal.business_name"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Address fiscal -->
              <b-form-group
                label="Dirección fiscal"
                label-for="address_fiscal"
              >
                <b-form-input
                  id="address_fiscal"
                  v-model="objetLocal.address_fiscal"
                  trim
                />
              </b-form-group>

              <!-- Department -->
              <b-form-group
                label="Departamento"
                label-for="department"
              >
                <b-form-input
                  id="department"
                  v-model="objetLocal.department"
                  trim
                />
              </b-form-group>

              <!-- province -->
              <b-form-group
                label="Provincia"
                label-for="province"
              >
                <b-form-input
                  id="province"
                  v-model="objetLocal.province"
                  trim
                />
              </b-form-group>

              <!-- district -->
              <b-form-group
                label="Distrito"
                label-for="district"
              >
                <b-form-input
                  id="district"
                  v-model="objetLocal.district"
                  trim
                />
              </b-form-group>

              <!-- Service of interest -->
              <b-form-group
                label="Servicio de interes"
                label-for="service_interest"
              >
                <v-select
                  v-model="objetLocal.service_interest"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="serviceInterest"
                  :clearable="false"
                  :reduce="val => val.value"
                  input-id="service_interest"
                />
              </b-form-group>

              <!-- Status business -->
              <b-form-group
                v-if="objetLocal.status_business"
                label="Estado"
                label-for="status_business"
              >
                <v-select
                  v-model="objetLocal.status_business"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="businessStatusOptions"
                  :clearable="false"
                  :reduce="val => val.value"
                  input-id="status_business"
                />
              </b-form-group>

              <!-- Payment qualifications -->
              <b-form-group
                v-if="objetLocal.status_business"
                label="Condición de pago"
                label-for="payment_qualification"
              >
                <v-select
                  v-model="objetLocal.payment_qualification_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentQualifications"
                  :clearable="false"
                  label="name"
                  :reduce="val => val.id"
                  input-id="payment_qualification"
                />
              </b-form-group>
            </template>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-if="existsRuc || objetLocal.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ objetLocal.id ? 'Actualizar' : 'Agregar ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import businessHandlerSidebar from '@/views/dramox/accounts/business/sidebar/businessHandlerSidebar'
import vSelect from 'vue-select'
import store from '@/store'

extend('digits_8_or_11', {
  validate(value) {
    return /^(?:\d{8}|\d{11})$/.test(value)
  },
  message: 'El campo {_field_} debe tener 8 o 11 dígitos.',
})

// Definir la regla de validación personalizada
extend('uniqueRuc', {
  // eslint-disable-next-line consistent-return
  async validate(value, args) {
    if (value.length === 11 || value.length === 8) {
      const response = await store.dispatch('app-business/checkIfRucExists', { ruc: value, id: args[0] })
      return !response.data.payload.results.exists
    }
    return true
  },
  message: 'EL RUC/DNI YA EXISTE',
})

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isBusinessHandlerSidebarActive',
    event: 'update:is-business-handler-sidebar-active',
  },
  props: {
    isBusinessHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    clearData: {
      type: Function,
      required: true,
    },
    serviceInterest: {
      type: Array,
      required: true,
    },
    paymentQualifications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {

    const userLoggerIsOwner = ref(true)

    const {
      objetLocal,
      resetLocal,
      onSubmit,
      existsRuc,
      existsInfo,
    } = businessHandlerSidebar(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetLocal(), props.clearData)

    const businessStatusOptions = [
      { label: 'CLIENTE', value: 'CLIENTE' },
      { label: 'POSIBLE CLIENTE', value: 'POSIBLE CLIENTE' },
      { label: 'NO CLIENTE', value: 'NO CLIENTE' },
    ]

    const existsBusinessByRuc = val => {
      if (val.length === 11 || val.length === 8) {
        store.dispatch('app-business/checkIfRucExists', { ruc: val, id: objetLocal.id })
          .then(response => {
            existsRuc.value = !response.data.payload.results.exists
            existsInfo.value.businessName = response.data.payload.results.businessName
            existsInfo.value.sellerName = response.data.payload.results.sellerName
            existsInfo.value.businessId = response.data.payload.results.businessId

            userLoggerIsOwner.value = response.data.payload.results.isOwner
          })
      } else {
        existsRuc.value = false
      }
    }

    return {
      // Add New
      objetLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      businessStatusOptions,
      existsBusinessByRuc,
      existsRuc,
      existsInfo,

      userLoggerIsOwner,

    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
</style>
